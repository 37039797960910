import { createSlice } from '@reduxjs/toolkit';

const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        paymentId: null,
        customerId: null,
    },
    reducers: {
        paymentId: (state, action) => {
            state.paymentId = action.payload;
        },
    },
});

export const { customerId, paymentId } = paymentSlice.actions;
export default paymentSlice.reducer;