import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Carousel() {
    const carouselContent = [
        {
            content: (
                <section className="section banner relative pb-0 min-h-[443px]">
                    <div className="container">
                        <div className="row flex-wrap items-center justify-center lg:flex-row min-h-[443px]">
                            <div className="mt-12 text-center lg:mt-0 lg:col-12">
                                <div className="banner-title">
                                    <h1>From Classroom to Cubicle <strong>:</strong></h1>
                                    <h2>GoGetterGeeks Paves Your Path to Top Tech Companies</h2>
                                </div>
                                <p className="mt-4">We offer programming courses and paid internship (select students)</p>
                                <div className="flex space-x-12 mt-6 justify-center">
                                    <img
                                        className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                                        src="/images/core/programming-gif/js-logo.gif"
                                        alt=""
                                    />
                                    <img
                                        className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                                        src="/images/core/programming-gif/cpp-logo-v4.gif"
                                        alt=""
                                    />
                                    <img
                                        className="inline-block h-12 w-12 rounded-full"
                                        src="/images/core/programming-gif/java-logo-v2.gif"
                                        alt=""
                                    />
                                    <img
                                        className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                                        src="/images/core/programming-gif/react-logo-v1.gif"
                                        alt=""
                                    />
                                </div>
                                <div className="space-x-4">
                                    <Link to="/#courses" className="btn btn-primary mt-6">
                                        View Courses
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            ),
        },
    ];

    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const nextContent = () => {
        setCurrentContentIndex((prevIndex) => (prevIndex + 1) % carouselContent.length);
    };

    const prevContent = () => {
        setCurrentContentIndex(
            (prevIndex) => (prevIndex - 1 + carouselContent.length) % carouselContent.length
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextContent();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const controlBackgroundColor = 'bg-gray-600';
    const controlTextColor = 'text-black';

    return (
        <section className="relative">
            <img
                className="absolute bottom-0 left-0 z-[-1] w-full"
                src="/images/core/banner-bg-shape.svg"
                width={1905}
                height={600}
                alt="banner-shape"
                priority
            />
            <div className="max-w-screen-xl mx-auto">
                <div className="relative">
                    <div className="w-full h-98 overflow-hidden">
                        <div
                            className="w-full flex"
                            style={{
                                transform: `translateX(-${currentContentIndex * 100}%)`,
                                transition: 'transform 0.5s ease-in-out',
                            }}
                        >
                            {carouselContent.map((content, index) => (
                                <div key={index} className="col-12">
                                    {content.content}
                                </div>
                            ))}
                        </div>
                        {carouselContent.size > 0 && (
                            <div className="mt-2 mb-2 flex justify-center">
                                {carouselContent.map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setCurrentContentIndex(index)}
                                        className={`w-4 h-4 rounded-full mx-1 ${currentContentIndex === index ? 'bg-gray-600' : 'bg-gray-300'
                                            }`}
                                    ></button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Carousel;
