import { Navigate, Route, Routes, useParams } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import AddToCart from "./pages/AddToCart";
import CheckoutPage from "./pages/CheckoutPage";
import AppLayout from "./layout/AppLayout";
import ScrollToElement from "./components/ScrollToElement";
import FinancialAid from "./pages/FinancialAid";
import Contact from "./pages/Contact";
import CourseDetails from "./pages/CourseDetails";
import RefundsAndCancellations from "./pages/legal/RefundsAndCancellations";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import PaymentSuccess from "./pages/PaymentSuccess";
import { useEffect } from "react";
import { initGA, logPageView } from "./analytics/GoogleAnalytics";
import RouteChangeTracker from "./analytics/RouteChangeTracker";
import BootcampDetails from "./pages/BootcampDetails";

function App() {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            initGA();
            logPageView();
        }
    }, []);

    const RedirectToShortCourses = () => {
        const { campId } = useParams();
        return <Navigate to={`/short-courses/${campId}`} replace />
      }

    return (
        <>
            <RouteChangeTracker />
            <ScrollToTop />
            <ScrollToElement />
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    {/* Nested routes */}
                    <Route index element={<LandingPage />} />
                    <Route path="about" element={<About />} />
                    <Route path="add-to-cart" element={<AddToCart />} />
                    <Route path="checkout" element={<CheckoutPage />} />
                    <Route path="payment-success" element={<PaymentSuccess />} />
                    <Route path="financial-aid" element={<FinancialAid />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="courses/:courseId" element={<CourseDetails />} />
                    <Route path="bootcamp/:campId" element={<RedirectToShortCourses />} />
                    <Route path="short-courses/:campId" element={<BootcampDetails />} />
                    <Route path="refunds-and-cancellations" element={<RefundsAndCancellations />} />
                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                </Route>
            </Routes>
        </>
    );
}

export default App;
