import ReactGA from "react-ga4";

export const initGA = () => {
    ReactGA.initialize("G-8TQDNWM9KB");
};

export const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const logEvent = (event) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event(event);
    }
};
