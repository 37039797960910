import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

function About() {
    return (
        <section className="light py-14 md:pt-4 lg:pb-4 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
            <div className="container px-4 py-6">
                <div className="grid grid-cols-12 md:gap-8">
                    <div className="col-span-12 lg:col-span-8">
                        <h2 className="text-2xl font-bold leading-none md:text-5xl md:leading-none my-6">
                            GoGetterGeeks' Story
                        </h2>
                        <p className="text-base sm:text-lg leading-relaxed tracking-wide break-words mt-2 md:mt-6 opacity-75">
                            GoGetterGeeks was founded with a passion for programming and a genuine desire to help others grow.
                            I'm Aditya Solge, a software developer with 6.5 years of experience at Amazon, having worked as both a Manager and Developer.
                            I hold a master’s degree from the University of Massachusetts, Boston, and my journey started at a tier-III engineering college in India.
                            This experience led me to believe that with the right guidance, anyone can achieve their goals.
                            GoGetterGeeks aims to bridge the gap between college education and the skills tech companies seek,
                            especially for students from tier-III colleges in India.
                        </p>
                        <p className="text-base sm:text-lg leading-relaxed tracking-wide break-words opacity-80 mt-4">
                            Our mission is to provide a strong foundation in programming and problem-solving skills, starting with C/C++ and advancing to Java,
                            Data Structures, and Web Development. Our 10-12 month courses prepare students for real-world challenges, and
                            those who complete the program are eligible for paid internships, offering valuable industry exposure and hands-on learning.
                        </p>
                        <p className="text-base sm:text-lg leading-relaxed tracking-wide break-words opacity-80 mt-4">
                            If you're ready to put in the work and become a software engineer, join our courses or contact us via the channels on our contact page.
                        </p>
                        <div className="grid grid-cols-12 mt-6 mb-6">
                            <div className="col-span-12 lg:col-span-4">
                                <Link to="https://www.linkedin.com/in/adityasolge/" target="_blank" rel="noopener noreferrer" className="px-6 py-3 bg-[#0077B5] text-white rounded-md hover:bg-[#005582] focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center space-x-2">
                                    <FaLinkedin size={24} className="text-white mb-1" />
                                    <span>Aditya Solge</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Profile picture */}
                    <div className="col-span-12 lg:col-span-4 order-2 lg:order-1">
                        <div className="z-[1] flex justify-center items-center relative h-full w-full">
                            <div>
                                <img
                                    src="/images/teacher/adityasolge-full.jpeg"
                                    alt="Aditya Solge"
                                    className="max-w-full h-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default About;