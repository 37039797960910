export const courses = [
    {
        id: "c-programming",
        category: "course",
        image: "/images/course/c-thumbnail.png",
        title: "C Programming",
        subTitle: "Start your programming journey with C",
        duration: "11 weeks",
        fee: "₹5,500",
        registrationFee: "₹299",
        start_date: "",
        class_schedule: "",
        description: {
            overview: {
                paragraph: `This course is designed for individuals with no prior experience in programming. 
                    C Programming is taught as the first language, with a focus on laying a strong foundation by ensuring students understand how a computer works behind the scenes. 
                    You'll learn the fundamentals in a way that ensures you will never have any issues learning new programming languages.`,
                bulletList: [
                    "Pre-Requisite: Must be in 9th grade",
                    "Class Frequency and Duration: 3 classes every week of 50 minutes each.",
                    "Mode of Teaching: Live interactive classes on Google Meet platform.",
                    "Language: Lectures will be delivered in English.",
                    "Batch Strength: 20-25 students.",
                ],
            },
            curriculum: {
                bulletList: [
                    "Introduction to Programming Languages and C",
                    "Data types",
                    "Decision, Loop, and Case Control",
                    "Functions",
                    "Pointers",
                    "Recursion",
                    "Arrays, Multi-dimensional array, Strings",
                    "Structure",
                    "Console I/O, File IO.",
                ],
            },
            courseProject: {
                subTitle: "Student Management CLI Application",
                paragraph: `You'll create a command-line interface (CLI) application that users can run from their command prompt or terminal. 
                They will be able to manage students by adding, deleting, updating, and fetching student information. 
                All student data will be stored in a binary file, and users of the application will be able to export student data to a CSV file 
                that can be opened with Microsoft Excel, Google Docs, and similar software.`
            },
            feePayment: {
                paragraph: `Register for the course for just ₹299/- and pay the rest after three classes in two installments.`,
                button: "yes"
            },
        },
    },
    {
        id: "cpp-programming",
        category: "course",
        image: "/images/course/c++-thumbnail.png",
        title: "C++ Programming",
        subTitle: "Learn Object-Oriented Programming in C++ and Desktop Application Development (Qt Creator)!",
        duration: "9 weeks",
        fee: "₹6,500",
        registrationFee: "₹299",
        start_date: "",
        class_schedule: "",
        description: {
            overview: {
                paragraph: `This course is designed to enhance your programming skills by teaching techniques adapted across the IT industry for project development of 
                        any kind (web apps, mobile apps, etc.). You'll also learn desktop application development through Qt Creator. 
                        The Qt Creator portion of the course consists of pre-recorded lectures in English and Hindi.`,
                bulletList: [
                    "Pre-Requisite: C Programming",
                    "Class Frequency and Duration: 3 classes every week of 50 minutes each.",
                    "Mode of Teaching: Live interactive classes on Google Meet platform.",
                    "Language: Lectures will be delivered in English.",
                    "Batch Strength: 20-25 students.",
                ],
            },
            curriculum: {
                bulletList: [
                    `Basics: C++ Syntax`,
                    `Object-Oriented Programming (OOP): Why OOP and What is OOP, Class and Object Creation, Encapsulation and Access Modifiers (Public/Private), Polymorphism (Function Overloading),
                        Dynamic Memory Allocation, Inheritance, Constructor & Destructor, This Pointer, Inheritance in case of Constructor/Destructor, Friend Function & Friend Class,
                        Operator Overloading, Inline Function, Static Members (Class and Function), Virtual Function/Pure Virtual Function,
                        Multiple Inheritance, Templates (Generic Type), File IO`,
                    `Qt Creator (Recorded Lectures): Qt Basics, Qt Basics through Code, Event-Driven Programming through Slots & Signals, In-App Navigation, StudentManagement GUI App - 3 parts,
                        Beyond StudentManagement GUI App`
                ],
            },
            courseProject: {
                subTitle: "Create Student Management Desktop Application!",
                paragraph: `You'll create a desktop application that can run on any operating system (Windows, Mac, and Linux). 
                        You'll apply the concepts learned during the course in the project and learn additional concepts regarding application development, 
                        such as event-driven programming, navigation, etc.`
            },
            feePayment: {
                paragraph: `Register for the course for just ₹299/- and pay the rest after three classes in two installments.`,
                button: "yes"
            },
        },
    },
    {
        id: "java-programming",
        category: "course",
        image: "/images/course/java-thumbnail.png",
        title: "Java Programming",
        subTitle: "Learn Core Java, Advanced Java, and Web Development in Java",
        duration: "28 weeks",
        fee: "₹10,000",
        registrationFee: "₹299",
        start_date: "",
        class_schedule: "",
        description: {
            overview: {
                paragraph: `This course will impart skills that will make you employable. The majority of the IT industry uses Java as their first-choice programming language. 
                        Many legacy systems are built using Java and will continue to exist for a long time due to the high cost of migration and the reliability offered 
                        by Java and its community. You'll learn Java in three major sections: Core Java, Advanced Java, and Web Development. Here are some course highlights:`,
                bulletList: [
                    "Pre-Requisite: Knowledge of at least one programming language (C, C++, Python, etc.)",
                    "Class Frequency and Duration: 3 classes every week of 50 minutes each.",
                    "Mode of Teaching: Live interactive classes on Google Meet platform.",
                    "Language: Lectures will be delivered in English.",
                    "Batch Strength: 20-25 students.",
                ],
            },
            curriculum: {
                bulletList: [
                    `Core Java: Introduction to Java and its Installation, Data Types, Operators (Type Casting, Automatic Promotion), Conditional/Loop Controls,
                        Object Creation, Local/Instance Variables, Polymorphism, Access Modifier, Encapsulation, Inheritance, This Keyword, Static Members, Final Method/Class,
                        Rules of Assignment (in context of Local/Object/Class Variables), System.out.println, Constructors, Initializer Block, Abstract Class/Method, 
                        Interface, Object Class, Object Comparison, Command-line Arguments, Exception Handling, Generic Type, Collection Framework, Collection Iteration,
                        File/Console IO, Packages`,
                    `Advanced Java: Lambda Function, Stream API, Multi-Threading, Advanced Multi-Threading, Socket Programming, JavaFX, SQL Database, MySQL CRUD Operations through Java`,
                    `Web Development: HTML, CSS, JavaScript, Bootstrap (Guided Learning), Tomcat Server, Web Application Creation - JSP/Servlet, Postman, RESTFul APIs, Unit Testing,
                        AuthN and AuthZ (using JWT), Swagger`
                ],
            },
            courseProject: {
                subTitle: "Total 3 Major Projects",
                paragraph: `As part of the Java course, you'll create three major projects designed to apply the concepts you learn throughout the course. 
                        These projects focus on how these concepts are used in the industry, including best practices, code structure, and project structure. 
                        You'll create a Student Management System that will allow users to perform CRUD (Create, Read, Update, Delete) operations. 
                        You'll develop a Command-line Interface application, a Desktop Application using JavaFX, and a Web Application using JSP Servlet Tomcat. 
                        You'll convert the code into REST APIs, accessible via Postman. The APIs can be converted using Swagger to generate an SDK for any programming language, 
                        allowing you to call these APIs from any programming language.`,
                videoId: "M4PNbpmmH18"
            },
            showcase: {
                subTitle: "Stick-Hero Game By: Aakarsh Yohaan (Student & Intern at GoGetterGeeks!)",
                paragraph: `Aakarsh applied what he learned from the course to develop a JavaFX-based stick game. It allows the player to decide the length of the growing stick so that 
                        the character can cross the hurdle. While crossing, the player has a chance to grab points that accumulate to form the player's score. 
                        During the development of the game, he only sought guidance and demonstrated the depth of his learning from the course. He seamlessly applied the concept 
                        of the complex topic of multi-threading without any side effects!`,
                videoId: "5kB7bf8mOsI"
            },
            feePayment: {
                paragraph: `Register for the course for just ₹299/- and pay the rest after three classes in two installments.`,
                button: "yes"
            },
        },
    },
    {
        id: "web-development-mern",
        category: "course",
        image: "/images/course/mern-thumbnail.png",
        title: "Web Development - MERN",
        subTitle: "Develop an e-commerce application to learn through a real-life use case.",
        duration: "14 weeks",
        fee: "₹10,000",
        registrationFee: "₹299",
        original_fee: "₹15,000",
        start_date: "31st March, 2025",
        class_schedule: "M,W,F 9-9:50 PM",
        description: {
            overview: {
                paragraph: `Learn web development through the MERN (MongoDB, ExpressJS, React, Node.js) stack. This course is designed to focus on teaching you how to teach yourself. 
                    Hence, expect a lot of assignments and guided tasks throughout this course. You'll have the chance to learn from official documentation, and gradually, 
                    you'll teach yourself how to learn through documentation. Here are some course highlights:`,
                bulletList: [
                    "Class Frequency and Duration: 3 classes every week of 50 minutes each.",
                    "Mode of Teaching: Live interactive classes on Google Meet platform.",
                    "Language: Lectures will be delivered in English.",
                    "Batch Strength: 20-25 students.",
                ],
            },
            curriculum: {
                bulletList: [
                    `Introduction to MERN (MongoDB, ExpressJS, React, NodeJS): Overview of the MERN stack and its components (MongoDB, ExpressJS, React, NodeJS, Node Package Manager).,
                        Why MERN is preferred for modern web development.`,
                    `Basic Concepts: Understanding HTML, JavaScript, and Bootstrap.`,
                    `React Fundamentals: Introduction to React and its core concepts, Javascript XML (JSX), Components and Props, Conditional rendering and list display,
                        Routing with React Router, Lifting the State Up, Use-Effect, Making HTTP requests with Axios, Redux`,
                    `Node.js & Express.js: Introduction to Node.js and asynchronous programming, Building RESTful APIs with Express.js, Middleware usage and error handling`,
                    `Authentication and Authorization: Implementing JWT-based authentication and authorization, Securing routes and resources`,
                    `MongoDB: Introduction to NoSQL databases and MongoDB, Understanding the difference between SQL and NoSQL databases, 
                        Database setup and CRUD operations - InsertOne, InsertMany, DeleteOne, DeleteMany, UpdateOne, UpdateMany, Find, FindAll, Find with Filter,
                        Advanced querying and indexing.`,
                    `Building an E-Commerce Application: Integrating Bootstrap for layout and styling, Setting up server-side with Node.js and Express.js, Database integration and data modelling,
                        User authentication and authorization using JWT and Secure Cookies, Developing client-side components with React - Login, Product, ProductDetails, Spinner,
                        Implementing shopping cart functionality using Cookies, Managing checkout workflows using state and multiple components`
                ],
            },
            courseProject: {
                subTitle: "Create your own E-Commerce Store!",
                paragraph: `You'll learn to create an e-commerce app working end-to-end, including the database! The course is designed to give you the freedom to 
                    improvise and provide ample learning opportunities.`,
                videoId: "T_KvTydRRMs"
            },
            feePayment: {
                paragraph: `Register for the course for just ₹299/- and pay the rest after three classes in two installments.`,
                button: "yes"
            },
        },
    },
];

export const getCourseById = (id) => {
    return courses.find((course) => course.id === id) || null;
};