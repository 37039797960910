import React from "react";

function FinancialAid() {
	return (
		<section className="light bg-white mt-0 mb-0 md:mt-10 md:mb-10">
			<div className="container px-4">
				<div className="bg-[#fff4e6] rounded-3xl overflow-hidden md:py-6">
					<div className="grid grid-cols-12">
						<div className="col-span-12 lg:col-span-12 lg:px-12">
							<div className="py-12 px-6 sm:px-12 text-center">
								<h2 className="text-2xl md:text-[40px] leading-none font-bold mb-6">
									Education {' >>>>>> '} Money!
								</h2>

								<div className="grid grid-cols-12 justify-center">
									<div className="col-span-12 sm:col-span-12">
										<p className="text-lg opacity-75 mb-0">
                                            GoGetterGeeks is born out of a genuine desire to help others in their growth. We strongly believe that education possesses the power to 
                                            transform not only individual lives but also the lives of future generations! In line with this belief, GoGetterGeeks welcomes 
                                            enthusiastic students who may face financial constraints to participate in our free learning program. 
                                            <br/><br/>
                                            If you are interested, kindly reach out to us at gogettergeeks@gmail.com, and we will guide you through the next steps. 
                                            Moreover, if you know someone who could benefit from this initiative, don't hesitate to share the same email address with them.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FinancialAid;