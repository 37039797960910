import { Link } from "react-router-dom";

function PrivacyPolicy() {
    return (
        <section className="section mb-12">
            <div className="container">
                <div className="">
                    <h1 className="mb-8 text-center">Privacy Policy</h1>
                    <p className="mt-4">
                        GoGetterGeeks respects your right to privacy. This Privacy Policy (“Policy”) explains:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Who we are,</li>
                            <li className="ms-4">How we collect, share, transfer, and use personal information about you,</li>
                            <li className="ms-4">How you can exercise your privacy rights.</li>
                        </ul>
                        This Policy applies to interactions with you via our websites, applications, and other products or services, including events,
                        where you contact us or where this Policy is displayed or linked (“Services”). It also applies in the course of our receipt of
                        products or services from you.
                    </p>

                    <h6 className="mt-6">Applicability</h6>
                    <p className="mt-2">
                        Some privacy rights and obligations may vary depending on your location, based on applicable local data protection laws. <br />
                        Third-party sites or applications linked to our Services may have their own privacy policies. Please review their policies to understand their practices.<br />
                        If a specific notice provided at the time of data collection conflicts with this Policy, the specific notice will apply.<br />
                        In cases where you enter into a separate agreement with us that requires data processing differing from this Policy, the terms of that agreement will apply.
                    </p>

                    <h6 className="mt-6">What Does GoGetterGeeks Do?</h6>
                    <p className="mt-2">
                        GoGetterGeeks provides live and recorded online courses on a variety of programming languages, including but not limited to C, C++, Java, and others.
                    </p>

                    <h6 className="mt-6">What Personal Data Do We Collect?</h6>
                    <p className="mt-2">
                        When you register for our courses, we collect the following personal data:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Name: To identify you and personalize your learning experience.</li>
                            <li className="ms-4">Phone Number: For essential course-related communications.</li>
                            <li className="ms-4">Email Address: Our primary method of keeping you informed and engaged.</li>
                        </ul>
                        This data is collected securely through online forms or other registration procedures necessary for enrollment.
                    </p>

                    <h6 className="mt-6">How We Use Your Data?</h6>
                    <p className="mt-2">
                        Your data is used solely for:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Course Registration and Enrollment: Ensuring a seamless start to your learning journey.</li>
                            <li className="ms-4">Course-Related Communication: Informing you about schedules, updates, and milestones.</li>
                            <li className="ms-4">Identity and Qualification Verification: Maintaining program integrity.</li>
                            <li className="ms-4">Occasional Promotional Emails: Sharing offers, newsletters, and updates.</li>
                        </ul>
                        You can opt out of promotional emails anytime by contacting us at <Link to="mailto:gogettergeeks@gmail.com"><strong>gogettergeeks@gmail.com</strong></Link>.
                    </p>

                    <h6 className="mt-6">Do We Share Your Data?</h6>
                    <p className="mt-2">
                        We do not sell, share, or disclose your data except for:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Payment Processing Services: To facilitate secure transactions.</li>
                            <li className="ms-4">Analytics Services: Such as Google Analytics and AWS Pinpoint, to enhance user experience and website performance.</li>
                        </ul>
                        Data shared with third parties is limited to these purposes and complies with applicable laws.
                    </p>

                    <h6 className="mt-6">Legal Basis for Processing Personal Information</h6>
                    <p className="mt-2">
                        We process your personal information based on:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Consent: When required under applicable laws.</li>
                            <li className="ms-4">Contractual Necessity: To fulfill agreements like course enrollment.</li>
                            <li className="ms-4">Legitimate Interests: For purposes such as improving Services, responding to queries, or processing payments.</li>
                            <li className="ms-4">Legal Compliance: When required by law.</li>
                        </ul>
                    </p>

                    <h6 className="mt-6">How Do We Keep Your Personal Information Secure?</h6>
                    <p className="mt-2">
                        We implement appropriate technical and organizational measures to safeguard your data. In case of a security incident, we will notify you promptly through email, correspondence, or other means as required by law.<br />
                        If you believe your data is at risk, contact our security team at <Link to="mailto:gogettergeeks@gmail.com"><strong>gogettergeeks@gmail.com</strong></Link>.
                    </p>

                    <h6 className="mt-6">How Do We Use Cookies?</h6>
                    <p className="mt-2">
                        Our website uses cookies to:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Enhance User Experience: Store preferences and provide a seamless browsing experience.</li>
                            <li className="ms-4">Analyze Performance: Monitor site behavior and navigation patterns using tools like Google Analytics and Amazon Pinpoint.</li>
                        </ul>
                        You can disable cookies in your browser settings, but this may limit certain functionalities.
                    </p>

                    <h6 className="mt-6">Data Retention</h6>
                    <p className="mt-2">
                        We retain personal information as long as there is a legitimate business need, such as providing requested Services or complying with legal requirements.<br />
                        Once no longer needed, we delete, anonymize, or securely store your information until deletion is possible. For specific details, contact us at <Link to="mailto:gogettergeeks@gmail.com"><strong>gogettergeeks@gmail.com</strong></Link>.
                    </p>

                    <h6 className="mt-6">Your Data Privacy Rights</h6>
                    <p className="mt-2">
                        Depending on your location, you may have the following rights:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Access: Request access to your personal information or details about its processing.</li>
                            <li className="ms-4">Deletion: Request deletion of your personal information (subject to legal obligations).</li>
                            <li className="ms-4">Corrections: Update or correct inaccurate information.</li>
                            <li className="ms-4">Opt-Out: Stop receiving promotional emails by contacting us at <Link to="mailto:gogettergeeks@gmail.com"><strong>gogettergeeks@gmail.com</strong></Link>.</li>
                        </ul>
                        Not all rights apply in all circumstances. We will inform you if any requests cannot be fulfilled and explain why.
                    </p>

                    <h6 className="mt-6">How to Contact Us</h6>
                    <p className="mt-2">
                        For complaints, clarifications, or concerns, contact our Grievance Officer:<br />
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Name: Aditya Solge</li>
                            <li className="ms-4">Email ID: gogettergeeks@gmail.com</li>
                            <li className="ms-4">Office Phone: +91 8886883905</li>
                        </ul>
                        The Grievance Officer will address issues within 28 business days and provide a resolution report.
                    </p>

                    <h6 className="mt-6">Notification of Changes</h6>
                    <p className="mt-2">
                        We will notify you of any changes to this Policy via email and updates on our website.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy;