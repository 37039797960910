import { AiOutlineLinkedin } from "react-icons/ai";
import { BsMedium } from "react-icons/bs";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer
            style={{
                backgroundImage: "url('/images/core/footer-bg-shape.svg')",
                backgroundSize: "cover",
                backgroundPosition: "left top",
            }}>
            <div className="container px-6 py-12 mx-auto">
                <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <h6 className="font-semibold text-gray-800 dark:text-white">Quick Link</h6>

                        <div className="flex flex-col items-start mt-5 space-y-2">
                            <Link to="/" className="text-dark hover:text-primary">Home</Link>
                            <Link to="/about" className="text-dark hover:text-primary">About</Link>
                            <Link to="/contact" className="text-dark hover:text-primary">Contact</Link>
                            <Link to="/financial-aid" className="text-dark hover:text-primary">Financial Aid</Link>
                            <Link to="/#courses" className="text-dark hover:text-primary">Courses</Link>
                        </div>
                    </div>

                    <div>
                        <h6 className="font-semibold text-gray-800 dark:text-white">Social Media</h6>

                        <div className="flex flex-col items-start mt-5 space-y-2">
                            <Link to="https://www.youtube.com/@gogettergeeks" target="_blank" className="text-dark hover:text-primary flex items-center space-x-3"><FiYoutube className="me-1 mb-1" size={24} />Youtube</Link>
                            <Link to="https://www.linkedin.com/in/adityasolge/" target="_blank" className="text-dark hover:text-primary flex items-center space-x-3"><AiOutlineLinkedin className="me-1 mb-1" size={24} />Linkedin</Link>
                            <Link to="https://adityasolge.medium.com/" target="_blank" className="text-dark hover:text-primary flex items-center space-x-3"><BsMedium className="me-1 mb-1" size={24} />Medium</Link>
                            <Link to="https://www.instagram.com/gogettergeeks/" target="_blank" className="text-dark hover:text-primary flex items-center space-x-3"><FaInstagram className="me-1 mb-1" size={24} />Instagram</Link>
                            <Link to="https://www.facebook.com/profile.php?id=61569960044129" target="_blank" className="text-dark hover:text-primary flex items-center space-x-3"><FaFacebook className="me-1 mb-1" size={24} />Facebook</Link>
                        </div>
                    </div>

                    <div>
                        <h6 className="font-semibold text-gray-800 dark:text-white">Legal</h6>

                        <div className="flex flex-col items-start mt-5 space-y-2">
                            <Link to="/privacy-policy" className="text-dark hover:text-primary">Privacy Policy</Link>
                            <Link to="/terms-and-conditions" className="text-dark hover:text-primary">Terms & Conditions</Link>
                            <Link to="/refunds-and-cancellations" className="text-dark hover:text-primary">Refunds & Cancellations</Link>
                        </div>
                    </div>

                    <div>
                        <h6 className="font-semibold text-gray-800 dark:text-white">Get in Touch</h6>

                        <div className="flex flex-col items-start mt-5 space-y-2">
                            <Link to="tel:+918886883905" className="text-dark hover:text-primary">+91 8886883905</Link>
                            <Link to="mailto:gogettergeeks@gmail.com" className="text-dark hover:text-primary">gogettergeeks@gmail.com</Link>
                            <p>Vistara Township, AB Bypass Road, Indore, Madhya Pradesh, India 453771</p>
                        </div>
                    </div>
                </div>

                <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />

                <div className="flex flex-col items-center justify-between sm:flex-row">
                    <p className="mt-4 text-sm text-gray-500 sm:mt-0 dark:text-gray-300">All Rights Reserved.</p>
                </div>
            </div>
        </footer>

    );
};

export default Footer;