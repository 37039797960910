import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        selectedCourse: null,
        cart: [],
    },
    reducers: {
        selectCourse: (state, action) => {
            state.selectedCourse = action.payload;
        },
        addToCart: (state, action) => {
            const existingItem = state.cart.find((item) => item.id === action.payload.id);
            if (!existingItem) {
                state.cart.push(action.payload);
            }
        },
        removeFromCart: (state, action) => {
            state.cart = state.cart.filter((item) => item.id !== action.payload);
        },
        clearCart: (state) => {
            state.cart = [];
        },
    },
});

export const { selectCourse, addToCart, removeFromCart, clearCart } = courseSlice.actions;
export default courseSlice.reducer;
