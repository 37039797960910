export default function AboutTeacher() {
    return (
        <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
            <div className="absolute inset-0 -z-10  opacity-20" />
            <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-gray-600/10 ring-1 ring-gray-100 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <h2 className="section-title text-center">Our Teacher</h2>
                <figure className="mt-10">
                    <blockquote className="text-center font-semibold leading-8 sm:leading-9">
                        <p>
                            Aditya Solge is a full-time freelancer with 8+ years of industry experience. He holds Master's Degree in Computer Science from University of
                            Masschussetts, Boston and worked with Amazon for 6+ years both as a developer and manager.
                            He decided to embark on a new journey, driven by his passion for coding and teaching.
                            Leaving his corporate job behind, he now channel his energy into freelancing while
                            also pursuing his love for education.
                        </p>
                    </blockquote>
                    <figcaption className="mt-10">
                        <img
                            className="mx-auto h-20 rounded-full"
                            src="/images/teacher/adityasolge.jpeg"
                            alt=""
                        />
                        <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                            <div className="font-semibold">Aditya Solge</div>
                        </div>
                    </figcaption>
                </figure>
            </div>
        </section>
    )
}
