import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { serverEndpoint, razorpayKey } from "../util/config";
import { paymentId } from '../feature/paymentSlice';

function CheckoutPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cartItems = useSelector((state) => state.course.selectedCourse);

    const filteredCartItems = Array.isArray(cartItems) 
    ? cartItems.map(({ id, title, start_date, class_schedule, category }) => ({ id, title, start_date, class_schedule, category })) 
    : { 
        id: cartItems.id, 
        title: cartItems.title, 
        start_date: cartItems.start_date, 
        class_schedule: cartItems.class_schedule,
        category: cartItems.category
    };

    const totalFee = location.state?.totalFee
        ? parseFloat(location.state.totalFee)
        : cartItems
            ? parseFloat(cartItems.registrationFee.replace(/[^0-9.-]+/g, ''))
            : 0;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        contact: '',
    });

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = { name: '', email: '', contact: '' };

        if (!formData.name) {
            newErrors.name = 'Name is required.';
        } else if (/\d/.test(formData.name)) {
            newErrors.name = 'Name should not contain numbers.';
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.contact) {
            newErrors.contact = 'Contact number is required.';
        } else if (!phoneRegex.test(formData.contact)) {
            newErrors.contact = 'Please enter a valid 10-digit phone number.';
        }

        setErrors(newErrors);

        return !Object.values(newErrors).some((error) => error);
    };

    const handlePayment = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setLoading(true);
                // Call backend to create Razorpay order
                const { data } = await axios.post(`${serverEndpoint}/payment/create-order`, {
                    amount: totalFee * 100,
                    currency: 'INR',
                    receipt: `receipt_${Date.now()}`,
                });
                const options = {
                    key: razorpayKey,
                    amount: totalFee * 100,
                    currency: "INR",
                    name: "Go Getter Geeks",
                    description: "Payment for course",
                    image: "/images/logo.png",
                    order_id: data.orderId,
                    prefill: {
                        name: formData.name,
                        email: formData.email,
                        contact: formData.contact,
                    },
                    handler: async (response) => {
                        setLoading(true);
                        const paymentDetails = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            name: formData.name,
                            email: formData.email,
                            phone: formData.contact,
                            items: filteredCartItems,
                        };
                        axios.post(`${serverEndpoint}/payment/success`, paymentDetails)
                            .then(response => {
                                dispatch(paymentId(response.data.paymentId));
                                window.location.href = `/payment-success`;
                            })
                            .catch(error => {
                                setErrorMessage(error.message);
                                setLoading(false);
                            });
                    },
                    remember_customer: false, 
                    modal: {
                        ondismiss: function () {
                            setLoading(false); // Stop loading if the modal is closed
                        }
                    },
                    theme: {
                        color: '#f39b11',
                    },
                };

                const razorpay = new window.Razorpay(options);
                razorpay.open();
            } catch (error) {
                setErrorMessage('Failed to create Razorpay order. Please try again.');
                setLoading(false);
            }
        }
    };

    const handleGoBack = () => {
        navigate('/add-to-cart');
    };

    return (
        <section className="py-14 md:py-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 animate-fadeIn">
            {loading ? (
                <div className="flex items-center justify-center min-h-screen">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="ml-4 text-lg font-semibold">Processing payment...</p>
                </div>
            ) : (
                <>
                    {!cartItems ? (
                        <div className="text-center bg-[#F7F7F7] dark:bg-gray-800 p-8 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">No course or cart items found</h3>
                            <p className="text-gray-700 dark:text-gray-300">
                                Please add items to your cart before proceeding to checkout.
                            </p>
                            <button
                                onClick={handleGoBack}
                                className="bg-primary text-white px-6 py-2 rounded-md shadow-md mt-6"
                            >
                                Go to Cart
                            </button>
                        </div>
                    ) : (
                        <div className="container mx-auto px-4">
                            <h2 className="text-2xl font-bold leading-none md:text-5xl md:leading-none my-6">Checkout</h2>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                                <div className="bg-[#F7F7F7] dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4">Billing Information</h3>
                                    <form>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 dark:text-gray-300">Student Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
                                                required
                                            />
                                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 dark:text-gray-300">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
                                                required
                                            />
                                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 dark:text-gray-300">Contact</label>
                                            <input
                                                type="tel"
                                                name="contact"
                                                value={formData.contact}
                                                onChange={handleInputChange}
                                                className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
                                                required
                                            />
                                            {errors.contact && <p className="text-red-500 text-sm">{errors.contact}</p>}
                                        </div>

                                        {errorMessage && (
                                            <p className="text-red-500 text-center mt-4">{errorMessage}</p>
                                        )}

                                        <div className="mt-6">
                                            <button
                                                className="bg-primary text-white px-6 py-2 rounded-md shadow-md w-full"
                                                onClick={handlePayment}
                                            >
                                                Proceed to Payment
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="lg:col-span-1">
                                    <div className="bg-[#F7F7F7] dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
                                        <ul className="space-y-4">
                                            {(Array.isArray(cartItems) ? cartItems : [cartItems]).map((item, index) => (
                                                <li key={index} className="flex justify-between">
                                                    <span>{item.title} (Registration fee)</span>
                                                    <span className="font-medium">{item.registrationFee}</span>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="border-t border-gray-300 dark:border-gray-700 mt-4 pt-4">
                                            <div className="flex justify-between text-lg font-semibold">
                                                <span>Total</span>
                                                <span>₹{totalFee.toLocaleString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </section>
    );

}

export default CheckoutPage;
