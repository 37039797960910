import Carousel from "../pages/home/Carousel";
import CourseCarousel from "./home/CourseCarousel";
import AboutTeacher from "./home/AboutTeacher";
import SocialMediaBanner from "./home/SocialMediaBanner";
import Testimonies from "../components/Testimonies";

function LandingPage() {
    return (
        <>
            <Carousel />
            <section className="section content-center sm:px-0">
                <div className="container">
                    <div className="row items-start">
                        <div className="mb-0 lg:mb-0 lg:col-12">
                            <CourseCarousel />
                            <AboutTeacher />
                            <SocialMediaBanner />
                            <Testimonies />
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default LandingPage;
