import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FaShoppingCart } from "react-icons/fa";

function Header() {
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const cartItems = useSelector((state) => state.course.cart);

    useEffect(() => {
        if (showMenu) {
            document.body.classList.add("menu-open");
        } else {
            document.body.classList.remove("menu-open");
        }
    }, [showMenu]);

    const handleMenuClose = () => {
        setShowMenu(false);
    };

    return (
        <header className="header">
            <nav className="navbar container px-1 sm:px-8">
                <div className="order-0">
                    <Link to="/">
                        <img src="/images/logo-text.png" className="h-14 w-auto" alt="GoGetterGeeks" />
                    </Link>
                </div>
                <div className="flex items-center space-x-4 xl:space-x-8">
                    <div className={`collapse-menu ${!showMenu && "translate-x-full"} lg:flex lg:translate-x-0`}>
                        <button className="absolute right-6 top-11 lg:hidden" onClick={handleMenuClose}>
                            <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                                <title>Menu Close</title>
                                <polygon
                                    points="11 9 22 9 22 11 11 11 11 22 9 22 9 11 -2 11 -2 9 9 9 9 -2 11 -2"
                                    transform="rotate(45 10 10)"
                                />
                            </svg>
                        </button>
                        <ul id="nav-menu" className="navbar-nav w-full md:w-auto md:space-x-1 lg:flex xl:space-x-2">
                            <li className="nav-item">
                                <Link to="/" className={`nav-link block ${location.pathname === '/' ? "active" : ""}`} onClick={handleMenuClose}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item nav-dropdown group relative">
                                <Link to='/#courses' className={`nav-link inline-flex items-center ${location.pathname.includes('/courses/') ? "active" : ""}`}>
                                    Courses
                                    <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </Link>
                                <ul className="nav-dropdown-list hidden transition-all duration-300 group-hover:top-[46px] group-hover:block md:invisible md:absolute md:top-[60px] md:block md:opacity-0 md:group-hover:visible md:group-hover:opacity-100 w-64">
                                    <li className="nav-dropdown-item">
                                        <Link to='/courses/c-programming' className={`nav-dropdown-link block ${location.pathname === '/courses/c-programming' ? "active" : ""}`} onClick={handleMenuClose}>
                                            C Programming
                                        </Link>
                                    </li>
                                    <li className="nav-dropdown-item">
                                        <Link to='/courses/cpp-programming' className={`nav-dropdown-link block ${location.pathname === '/courses/cpp-programming' ? "active" : ""}`} onClick={handleMenuClose}>
                                            C++ Programming
                                        </Link>
                                    </li>
                                    <li className="nav-dropdown-item">
                                        <Link to='/courses/java-programming' className={`nav-dropdown-link block ${location.pathname === '/courses/java-programming' ? "active" : ""}`} onClick={handleMenuClose}>
                                            Java Programming
                                        </Link>
                                    </li>
                                    <li className="nav-dropdown-item">
                                        <Link to='/courses/web-development-mern' className={`nav-dropdown-link block ${location.pathname === '/courses/web-development-mern' ? "active" : ""}`} onClick={handleMenuClose}>
                                            Web Development (MERN)
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item nav-dropdown group relative">
                                <Link className={`nav-link inline-flex items-center ${location.pathname.includes('/short-courses/') ? "active" : ""}`}>
                                    Short Courses
                                    <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </Link>
                                <ul className="nav-dropdown-list hidden transition-all duration-300 group-hover:top-[46px] group-hover:block md:invisible md:absolute md:top-[60px] md:block md:opacity-0 md:group-hover:visible md:group-hover:opacity-100 w-64">
                                    <li className="nav-dropdown-item">
                                        <Link to='/short-courses/summer-coding-camp' className={`nav-dropdown-link block ${location.pathname === '/short-courses/summer-coding-camp' ? "active" : ""}`} onClick={handleMenuClose}>
                                            Summer Coding Camp
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className={`nav-link block ${location.pathname === '/about' ? "active" : ""}`} onClick={handleMenuClose}>
                                    About
                                </Link>
                            </li>
                        </ul>
                        <ul className="socials">
                            <li className="nav-item mt-1">
                                <Link to="/add-to-cart" className={`nav-link block ${location.pathname === '/add-to-cart' ? "active" : ""}`} onClick={handleMenuClose}>
                                    <FaShoppingCart size={24} className="p-1" />
                                    {cartItems.length > 0 && (
                                        <sup className="superscript">
                                            {cartItems.length}
                                        </sup>
                                    )}
                                </Link>
                            </li>
                            {/* <li className="nav-item mt-1">
                                <Link
                                    to="#"
                                    className={`nav-link block`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = subdomainUrl;
                                        handleMenuClose();
                                    }}
                                >
                                    <FaUser size={24} className="p-1" />
                                </Link>
                            </li> */}
                        </ul>
                    </div>

                    <button
                        onClick={() => setShowMenu(!showMenu)}
                        className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white lg:hidden"
                    >
                        {showMenu ? (
                            <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                                <title>Menu Close</title>
                                <polygon
                                    points="11 9 22 9 22 11 11 11 11 22 9 22 9 11 -2 11 -2 9 9 9 9 -2 11 -2"
                                    transform="rotate(45 10 10)"
                                />
                            </svg>
                        ) : (
                            <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                                <title>Menu Open</title>
                                <path d="M0 3h20v2H0V3z m0 6h20v2H0V9z m0 6h20v2H0V0z" />
                            </svg>
                        )}
                    </button>
                </div>
            </nav>
            {showMenu && (
                <div className="header-backdrop absolute top-0 left-0 h-[100vh] w-full bg-black/50 lg:hidden"></div>
            )}
        </header>
    );
};

export default Header;
