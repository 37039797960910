import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCourse, removeFromCart } from '../feature/courseSlice';

function AddToCart() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cartItems = useSelector((state) => state.course.cart);

    const parseFee = (fee) => parseFloat(fee.replace(/[^0-9.-]+/g, ''));
    const totalFee = cartItems?.length
        ? cartItems.reduce((total, item) => {
            const fee = parseFee(item.registrationFee);
            return !isNaN(fee) ? total + fee : total;
        }, 0)
        : 0;
    const formattedTotal = `₹${totalFee.toLocaleString()}`;

    const handleProceedToCheckout = () => {
        dispatch(selectCourse(cartItems));
        navigate('/checkout', { state: { totalFee: totalFee } });
    };

    const handleBrowseCourses = () => navigate('/#courses');

    const handleRemoveCourse = (id) => {
        dispatch(removeFromCart(id));
    };

    return (
        <section className="py-14 md:py-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 animate-fadeIn">
            {cartItems && cartItems.length > 0 ? (
                <div className="container mx-auto px-4">
                    <h2 className="text-2xl font-bold leading-none md:text-5xl md:leading-none my-6">
                        Cart
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Course Details Section */}
                        <div className="lg:col-span-1">
                            <div className="bg-[#F7F7F7] dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                <div className="space-y-6">
                                    {cartItems.map((item, index) => (
                                        <div
                                            key={index}
                                            className="relative border border-gray-300 dark:border-gray-700 rounded-lg p-6 flex flex-col lg:flex-row items-center lg:items-start space-y-4 lg:space-y-0 lg:space-x-6"
                                        >
                                            <button
                                                className="absolute top-2 right-2 bg-primary text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold"
                                                onClick={() => handleRemoveCourse(item.id)}
                                            >
                                                ✕
                                            </button>
                                            <img
                                                className="rounded w-40 h-47 object-cover"
                                                src={item.image}
                                                alt={item.title}
                                            />
                                            <div className="flex-1">
                                                <h3 className="text-lg font-semibold text-center lg:text-left">{item.title}</h3>
                                                <p className="text-gray-600 dark:text-gray-400 mt-1 text-center lg:text-left">
                                                    Price: <span className="font-medium">{item.fee}</span> <br />
                                                    Registraion Fee: <span className="font-medium">{item.registrationFee}</span>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Order Summary Section */}
                        <div className="lg:col-span-1">
                            <div className="bg-[#F7F7F7] dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                <h2 className="text-xl font-bold mb-4">Order Summary</h2>
                                <ul className="space-y-4">
                                    {cartItems.map((item, index) => (
                                        <li key={index} className="flex justify-between">
                                            <span>{item.title} (Registration fee)</span>
                                            <span className="font-medium">{item.registrationFee}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className="border-t border-gray-300 dark:border-gray-700 mt-4 pt-4">
                                    <div className="flex justify-between text-lg font-semibold">
                                        <span>Total</span>
                                        <span>{formattedTotal}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 flex space-x-4">
                                <button
                                    className="bg-primary text-white px-6 py-2 rounded-md shadow-md"
                                    onClick={handleProceedToCheckout}
                                >
                                    Proceed to checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center bg-[#F7F7F7] dark:bg-gray-800 p-8 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-4">No course or cart items found</h3>
                    <p className="text-gray-700 dark:text-gray-300">
                        Your cart is currently empty. Browse our courses to find what you're looking for!
                    </p>
                    <button
                        className="bg-primary text-white px-6 py-2 rounded-md shadow-md mt-6"
                        onClick={handleBrowseCourses}
                    >
                        Browse Courses
                    </button>
                </div>
            )}
        </section>
    );
}

export default AddToCart;
