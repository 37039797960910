export const seoConfig = {
    "/": {
        title: "GoGetterGeeks",
        description: `Bridging the gap between college education and top-tier tech firms, 
            our initiative equips students with essential software development skills for thriving careers in IT.`
    },
    "/about": {
        title: "About - GoGetterGeeks",
        description: "Know more about GoGetterGeeks and how we help you with programming education."
    },
    "/contact": {
        title: "Contact Us - GoGetterGeeks",
        description: "Get in touch with GoGetterGeeks for inquiries and support."
    },
    "/financial-aid": {
        title: "Financial Aid - GoGetterGeeks",
        description: `Discover how our financial aid program supports students in need to ensure equal access to education. 
            Learn more about eligibility and how to apply today!`
    },
    "/refunds-and-cancellations": {
        title: "Refunds and Cancellations - GoGetterGeeks",
        description: `Learn about GoGetterGeeks' Refunds and Cancellation policy`
    },
    "/terms-and-conditions": {
        title: "Terms and Conditions - GoGetterGeeks",
        description: `Learn about GoGetterGeeks' Terms and Conditions`
    },
    "/privacy-policy": {
        title: "Privacy Policy - GoGetterGeeks",
        description: `Learn about GoGetterGeeks' Privacy Policy`
    },
    "/courses/c-programming": {
        title: "C Programming - GoGetterGeeks",
        description: `Learn C Programming with GoGetterGeeks! Beginner-friendly course, 3 free classes, live sessions, real-world project. Financial aid available. Enroll now!`
    },
    "/courses/cpp-programming": {
        title: "C++ Programming - GoGetterGeeks",
        description: `Master C++ Programming with GoGetterGeeks! Learn OOP, dynamic memory, and desktop app development using Qt Creator. 3 free classes, financial aid available!`
    },
    "/courses/java-programming": {
        title: "Java Programming - GoGetterGeeks",
        description: `Learn Java with GoGetterGeeks! Master Core Java, Advanced Java, and Web Development in 28 weeks. 3 free classes, hands-on projects, financial aid available!`
    },
    "/courses/web-development-mern": {
        title: "Web Development - GoGetterGeeks",
        description: `Master Web Development with MERN! Build an E-Commerce app, learn hands-on with live classes, and assignments. Financial aid available. Enroll now!`
    }
    // Add more paths here
};