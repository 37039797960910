function NotFound404() {
    return (
        <section className="section">
            <div className="container">
                <div className="flex h-[40vh] items-center justify-center">
                    <div className="text-center">
                        <h1 className="mb-4">404</h1>
                        <p>Page Not Available!</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFound404;