function RefundsAndCancellations() {
    return (
        <section className="section mb-12 lg:mb-72 md:mb-40">
            <div className="container">
                <div className="">
                    <h1 className="mb-8 text-center">Refunds and Cancellations</h1>
                    <p className="">
                        We maintain a strict no-refund and no-cancellation policy. To ensure your satisfaction, we offer three complimentary classes before requiring the full course fee payment.
                        Please note that a non-refundable registration fee is charged during the enrollment process.
                        Refunds are only applicable in cases where the course has not been allotted to you after payment.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default RefundsAndCancellations;