export const bootcamps = [
    {
        id: "summer-coding-camp",
        category: "bootcamp",
        title: "Summer Coding Camp",
        subtitle: "Learn coding basics in 3 days",
        duration: "3-day",
        registrationFee: "₹999",
        original_fee: "₹1100",
        start_date: "1st May, 2025",
        class_schedule: "10 AM",
        description: {
            readyToExploreTheWorldOfCoding: {
                paragraph: `Hey there! Want to kick-start your coding journey but feeling unsure about how, when, or where to begin? Don’t worry—we’ve got you covered! Go Getter Geek is launching its very own Summer Boot Camp for the upcoming season.
                This interactive camp is designed to spark your curiosity and creativity in coding through engaging sessions where you’ll explore your potential and dive into the world of tech and innovation. It’s a super fun three-day experience where you’ll gain hands-on coding skills and a deeper understanding of how technology works.
                If you're in 7th grade or above, have a laptop or PC, and a stable internet connection, you’re all set to join. Just register and ride the CODE waves under the summer sun!`
            },
            day1: {
                MorningSession: {
                    displayName: "Morning Session",
                    duration: "1.5 Hours",
                    bulletList: [
                        "1. Introduction to Computers: You/Your child will explore how a computer works behind the scenes in an engaging way. Learn how electricity powers computers and gets translated into human-readable languages like English.",
                        "2. How Computers work: We’ll learn binary number systems—how electrical signals are transformed into binary code, and how that code is converted into meaningful interactions, like the text and visuals we see on a screen.",
                        "3. Introduction to Programming Languages: Moving ahead your child/you  will discover what programming languages are and why they’re so important. You’ll see how programming is used to create the software they interact with daily, like Google Chrome or their favorite apps.",
                        "4. C Programming - Installation & Setup: We’ll guide the kids step-by-step in setting up the tools they need to start coding in the C language. Before the camp begins, please ensure they have permission to install software on their devices, as this will be a hands-on learning experience.",
                    ],
                },
                AfternoonSession: {
                    displayName: "Afternoon Session",
                    duration: "1.5 Hours",
                    bulletList: [
                        "1. Data Types: We’ll explain how data is stored in a computer’s memory using data types. Your child will learn about the different storage sizes provided by programming languages and how to use them effectively.",
                        "2. Condition Control: We’ll introduce how conditions can be used to control the behavior of software. For example, your child will learn how to use conditional statements to determine a student’s grade based on the marks scored in a specific subject.",
                    ]
                }
            },
            day2: {
                MorningSession: {
                    displayName: "Morning Session",
                    duration: "1.5 Hours",
                    bulletList: [
                        "1. Loop Controls: We’ll explain how repetitive tasks, like printing “I love pizza” 100 times, can be efficiently achieved using just 3-4 lines of code with Loop Control. Your child will also learn how to combine Condition Control and Loop Control to solve more complex problems.",
                        "2. Arrays: We’ll teach how multiple memory locations in RAM can be used to store the same type of data. For instance, your child will learn how to store data for 100 students together in RAM, allowing for efficient data management."
                    ]
                },
                AfternoonSession: {
                    displayName: "Afternoon Session",
                    duration: "1.5 Hours",
                    bulletList: [
                        "1. Functions: We’ll teach how repetitive tasks can be automated using functions. For example, your child will learn how to write a function to calculate the sum of numbers, allowing the same code to be reused multiple times without redundancy.",
                        "2. Project Brainstorming: We’ll provide a pre-compiled list of project ideas, from which your child can choose one to work on. They will work individually on their selected project and receive personalized guidance on how to approach its development. This session will focus on the planning and preparation required to implement the project effectively."
                    ]
                }
            },
            day3: {
                MorningSession: {
                    displayName: "Morning Session",
                    duration: "2 Hours",
                    bulletList: [
                        "* Project Implementation: Kids will take the project plan they created in the previous session and write code to bring it to life. They will also learn how to package their project into executable files that can be run on any laptop, allowing them to share their work with others!",
                    ]
                },
                AfternoonSession: {
                    displayName: "Afternoon Session",
                    duration: "1 Hour",
                    bulletList: [
                        "* Closing Notes: We’ll provide an overall summary of the concepts learned throughout the camp and offer guidance on how kids can continue their coding journey. We highly recommend parents participate in this session to gain insight into their child's progress.",
                        "* Parent Interaction: We’ll host an open Question and Answer session where parents can ask any questions they have regarding coding or software development as a career option. This is an excellent opportunity for parents, especially those from non-technical backgrounds, to learn about the field and make informed decisions for their child’s future—particularly in an era where large companies may prioritize profits over their children's best interests."
                    ],
                    button: "yes"
                }
            }
        },
    },
];

export const getCampDetailsById = (id) => {
    return bootcamps.find((bootcamp) => bootcamp.id === id) || null;
};