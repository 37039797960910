import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { serverEndpoint } from "../util/config";
import { clearCart } from '../feature/courseSlice';
import { subdomainUrl } from '../util/config';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const paymentId = useSelector((state) => state.payment.paymentId);

    const [paymentDetails, setPaymentDetails] = useState(null);
    const [error, setError] = useState('');

    const navigateToSubdomain = () => {
        window.location.href = subdomainUrl;
    };

    const handleLogin = () => {
        dispatch(clearCart());
        navigateToSubdomain();
    };

    const handleContinueShopping = () => {
        dispatch(clearCart());
        navigate('/');
    }

    const handleContinuePayment = () => {
        navigate('/checkout');
    };

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            try {
                const response = await axios.get(`${serverEndpoint}/payment/getPaymentDetails/${paymentId}`);
                setPaymentDetails(response.data.data);
            } catch (err) {
                setError('Failed to fetch payment details. Please try again.');
            }
        };

        if (paymentId) {
            fetchPaymentDetails();
        }
    }, [paymentId]);

    return (
        <section className="py-14 md:py-16 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
            <div className="container mx-auto px-4">
                {/* Display error if present */}
                {error ? (
                    <div className="text-center bg-red-100 dark:bg-red-800 p-8 rounded-lg shadow-lg mb-8">
                        <h2 className="text-3xl font-extrabold text-red-600 dark:text-red-400 mb-4">
                            Payment Failed
                        </h2>
                        <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                            {error}
                        </p>

                        <div>
                            <button
                                onClick={handleContinuePayment}
                                className="bg-primary text-white px-6 py-2 rounded-md shadow-md mt-4"
                            >
                                Continue Payment
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="text-center bg-green-100 dark:bg-green-800 p-8 rounded-lg shadow-lg mb-8">
                        <h2 className="text-3xl font-extrabold text-green-600 dark:text-green-400 mb-4">
                            Payment Successful
                        </h2>
                        <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                            Your payment has been successfully processed. You will receive an email confirmation soon.
                        </p>

                        {/* Display payment details only if no error */}
                        {paymentDetails && (
                            <div>
                                <p><strong>Receipt ID:</strong> {paymentDetails.order_id}</p>
                                <p><strong>Amount Paid:</strong> ₹{(paymentDetails.amount / 100).toFixed(2)}</p>
                                <p><strong>Payment ID:</strong> {paymentDetails.id}</p>
                                <p><strong>Status:</strong> {paymentDetails.status}</p>
                            </div>
                        )}
                        <div>
                            {/* <button
                                onClick={handleLogin}
                                className="bg-primary text-white px-6 py-2 rounded-md shadow-md mt-4"
                            >
                                Login
                            </button> */}
                            <button
                                onClick={handleContinueShopping}
                                className="bg-primary text-white px-6 py-2 rounded-md shadow-md mt-4"
                            >
                                Continue Shopping
                            </button> 

                        </div>
                    </div>
                )}


            </div>

        </section>
    );
};

export default PaymentSuccess;
