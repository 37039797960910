import Slider from "react-slick";
import { courses } from "../../content/courses";
import { Link } from "react-router-dom";

function CourseCarousel() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div id="courses" className="scroll-id-link">
            <h2 className="section-title text-center">Courses</h2>
            <div className="rounded border border-border pt-6 dark:border-darkmode-border mb-4">
                <div className="row">
                    <Slider {...settings}>
                        {courses.map((course) => (
                            <div className="mb-8 md:col-4 px-4" key={course.id}>
                                <div className="rounded transition duration-300 ease-in-out hover:scale-105 border dark:border-slate-700 h-full">
                                    <div className="relative">
                                        <Link to={`/courses/${course.id}`}>
                                            <img
                                                className="rounded"
                                                src={course.image}
                                                alt={course.title}
                                                width={405}
                                                height={208}
                                            />
                                        </Link>
                                    </div>
                                    <div className="p-2 text-start">
                                        <Link to={`/courses/${course.id}`}>
                                            <h5 className="font-bold hover:text-primary mb-1">
                                                {course.title}
                                            </h5>
                                        </Link>
                                        <h5 className="text-sm mt-3 leading-none opacity-60 font-medium">
                                            Duration: {course.duration}
                                        </h5>
                                        <div className="pt-4 pb-1 flex items-center">
                                            <h5 className="text-2xl flex font-medium text-gray-600">
                                                {course.fee}
                                            </h5>
                                            {course.original_fee && (
                                                <h5 className="text-[15px] opacity-50 line-through ml-2">
                                                    {course.original_fee}
                                                </h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default CourseCarousel;