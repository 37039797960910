import { Link } from "react-router-dom";

function TermsAndConditions() {
    return (
        <section className="section mb-12">
            <div className="container">
                <div className="">
                    <h1 className="mb-8 text-center">Terms and Conditions</h1>
                    <p className="mt-4">
                        Welcome to GoGetterGeeks. These Terms and Conditions outline the rules and regulations for the use of our website and services.
                        By accessing or using our services, you agree to these Terms and Conditions. If you do not agree, please refrain from using our services.
                    </p>

                    <h6 className="mt-6">1. Definitions</h6>
                    <p className="mt-2">
                        1.1 "We," "Us," "Our" refer to GoGetterGeeks, located in Indore, Madhya Pradesh, India. <br />
                        1.2 "You," "User" refers to any individual accessing our services.<br />
                        1.3 "Services" refers to live, interactive coding classes conducted through platforms like Google Meet.
                    </p>

                    <h6 className="mt-6">2. Eligibility</h6>
                    <p className="mt-2">
                        2.1 By using our services, you confirm that you are at least 18 years old or have parental/guardian consent if under 18.<br />
                        2.2 You agree to provide accurate and complete information when registering for our services.
                    </p>

                    <h6 className="mt-6">3. User Data</h6>
                    <p className="mt-2">
                        3.1 We collect your name, email, and phone number solely for communication and service delivery purposes.<br />
                        3.2 By providing your information, you consent to its use as outlined in our <Link to="/privacy-policy" className="text-dark hover:text-primary">Privacy Policy</Link>. <br />
                        3.3 Your data will not be shared with third parties except as required by law.
                    </p>

                    <h6 className="mt-6">4. Payment and Refund Policy</h6>
                    <p className="mt-2">
                        4.1 Fees for our services must be paid in advance.<br />
                        4.2 Refunds, if applicable, are subject to our <Link to="/refunds-and-cancellations" className="text-dark hover:text-primary">Refund Policy</Link>.<br/>
                        4.3 Non-payment may result in suspension or termination of services.
                    </p>

                    <h6 className="mt-6">5. Class Conduct</h6>
                    <p className="mt-2">
                        5.1 Classes are conducted via Google Meet. You must adhere to Google Meet's terms of service during sessions.<br />
                        5.2 Disruptive behavior, harassment, or misconduct will result in immediate removal without refund.
                    </p>

                    <h6 className="mt-6">6. Intellectual Property</h6>
                    <p className="mt-2">
                        6.1 All course materials, including recordings, documents, and slides, are the intellectual property of GoGetterGeeks.<br/>
                        6.2 Users may not share, distribute, or reproduce any course material without prior written consent.
                    </p>

                    <h6 className="mt-6">7. Limitation of Liability</h6>
                    <p className="mt-2">
                        7.1 We strive to provide high-quality coaching but do not guarantee specific outcomes or results.<br />
                        7.2 We are not liable for technical issues, including connectivity or software problems during online sessions.
                    </p>

                    <h6 className="mt-6">8. Cancellations and Rescheduling</h6>
                    <p className="mt-2">
                        8.1 We reserve the right to cancel or reschedule classes due to unforeseen circumstances.<br />
                        8.2 Users will be notified promptly of any changes.
                    </p>

                    <h6 className="mt-6">9. Governing Law and Jurisdiction</h6>
                    <p className="mt-2">
                        9.1 These Terms and Conditions are governed by the laws of India.<br />
                        9.2 Any disputes shall be subject to the exclusive jurisdiction of the courts in Indore, Madhya Pradesh, India.
                    </p>

                    <h6 className="mt-6">10. Amendments</h6>
                    <p className="mt-2">
                        10.1 We may update these Terms and Conditions periodically.<br/>
                        10.2 Users are advised to review these terms regularly to stay informed of any changes.
                    </p>

                    <h6 className="mt-6">11. Contact Us</h6>
                    <p className="mt-2">
                        For any queries regarding these Terms and Conditions, please contact us at:
                        <ul className="list-disc list-inside space-y-0">
                            <li className="ms-4">Email: gogettergeeks@gmail.com</li>
                            <li className="ms-4">Phone: +91 8886883905</li>
                        </ul>
                    </p>

                    <p className="mt-6">
                        By using our services, you confirm that you have read, understood, and agree to abide by these Terms and Conditions.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default TermsAndConditions;