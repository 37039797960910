import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

function Youtube({ id, title, ...rest }) {
    return (
        <div className="overflow-hidden rounded w-full sm:w-full md:w-full lg:w-[560px] xl:w-[640px]">
            <LiteYouTubeEmbed id={id} title={title} {...rest} />
        </div>
    );
}

export default Youtube;
