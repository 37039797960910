const testimonials = [
    {
        img: "/images/logo.png",
        name: "Aakarsh Yohaan",
        position: "Student (Java, Web Development), Intern at GoGetterGeeks",
        content:
            `The Java course by Aditya (at GoGetterGeeks) was one of the best decisions I have taken in my life. The course covers everything a person needs to prepared for a professional career.
             Aditya's impeccable knowledge and professionalism ensures that all doubts and concepts are cleared to the highest extent.
             Would definitely recommend GoGetterGeeks to anyone interested in computer science.`,
    },
    {
        img: "/images/logo.png",
        name: "Krish Joshi",
        position: "Student (C, C++, Java)",
        content:
            `I recently had the privilege of learning from an exceptional coding mentor who possesses a wealth of experience and knowledge in the field. 
            Their teaching style is both engaging and effective, making complex coding concepts easily understandable. 
            With their guidance, I've not only improved my coding skills but also gained valuable insights into problem-solving techniques and industry best practices.`,
    },
    {
        img: "/images/logo.png",
        name: "Anika Chopra",
        position: "Student (C, C++, Java)",
        content:
            `I joined Aditya sir when I was just starting my engineering and was very new to programming. 
            Aditya Sir is very detailed oriented, handled all my questions with utmost depth and focusses a lot on core concepts.
            I can clearly relate between theory concepts and how they are implemented in real world. 
            Aditya Sir's assignments are extremely helpful and focuses on IT problem statements.`,
    },
    {
        img: "/images/logo.png",
        name: "Sriram",
        position: "Student (Web Development)",
        content:
            `I was taking many classes from aditiya sir previously he was teaching me dsa he taught me very well solved my doubts and 
            helped me how to approach the dsa problems. Recently I joined his mern stack the fee is very affordable when compared 
            to other companies or centers he gives assignments and also clears very doubt now iam good in react js and my interest 
            in web development is increased so you can trust him and come to his course`,
    },
    {
        img: "/images/logo.png",
        name: "Abdul Fazil",
        position: "Student (Web Development)",
        content:
            `I like the way of teaching by Aditya solge, he's clearing my doubts and make me consistent in learning, even with his experience 
            he make course affordable for everyone, if I try to learn the same thing from other paid courses it cost atleast thrice the amount, 
            even then that are mostly pre-recorded videos. Thank you for your service.`,
    },
    {
        img: "/images/logo.png",
        name: "Shaman Patel",
        position: "Student (Java)",
        content:
            `Over the past four months, he has guided me through Java and Advanced Java with a teaching style that is both engaging and highly effective.
            Aditya brings a unique blend of technical expertise and industry insight into every lesson, allowing students to grasp not only the theory but also the practical application of each concept.
            Every session is meticulously planned, with a focus on clarity, depth, and real-world relevance. He has a gift for explaining 
            even the smallest details.`,
    },
];

export default testimonials;