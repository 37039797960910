import React from "react";
import Slider from "react-slick";
import testimonials from "../content/testimonies";

export default function Testimonies({ includeHeading = true }) {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="py-8 sm:py-8">
            <div className="">
                {includeHeading && (
                    <div className="mx-auto max-w-2xl lg:text-center py-8">
                        <h2 className="section-title text-center">What They Say</h2>
                    </div>
                )}

                <div className="slider-container">
                    <Slider {...settings}>
                        {testimonials.map((testimonial, i) => (
                            <div className="col-span-3 lg:col-span-1" key={i}>
                                <div className="h-full p-6 xl:p-10">
                                    <div className="flex items-center mb-6">
                                        <div className="mr-3 mb-2">
                                            <img
                                                src={testimonial.img}
                                                alt="..."
                                                className="max-w-full h-auto"
                                                width="50"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="text-xl font-medium">
                                                {testimonial.name}
                                            </h4>
                                            <p className="text-sm mb-2">
                                                {testimonial.position}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="opacity-75 mb-2">{testimonial.content}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}