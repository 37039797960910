import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCampDetailsById } from "../content/bootcamps";
import { selectCourse } from '../feature/courseSlice';
import NotFound404 from '../components/404';
import { logEvent } from '../analytics/GoogleAnalytics';
import { FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import Testimonies from '../components/Testimonies';
import { IoMdCall } from 'react-icons/io';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { serverEndpoint } from '../util/config';
import { downloadFile } from '../util/downloadS3File';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import AutoDismissibleAlert from '../components/AutoDismissibleAlert';

function BootcampDetails() {
    const buttonRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [formData, setFormData] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsSticky(!entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (buttonRef.current) {
            observer.observe(buttonRef.current);
        }

        return () => {
            if (buttonRef.current) {
                observer.unobserve(buttonRef.current);
            }
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = { email: '' };

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        setErrors(newErrors);

        return !Object.values(newErrors).some((error) => error);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await axios.post(`${serverEndpoint}/reach/get-service-info`, {
                    email: formData.email,
                    service_id: campId
                });

                await downloadFile(response.data.downloadUrl, "g3-summer-coding-camp.pdf");
                setMessage('Your file is ready and will be downloaded automatically');
                setFormData({ email: '' });
            } catch (error) {
                console.log(error);
                setErrors({ message: 'Failed to create Razorpay order. Please try again.' });
            } finally {
                setLoading(false);
            }
        }
    };

    const { campId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const camp = getCampDetailsById(campId);

    const isAfter = (date) => {
        const currentDate = new Date();
        const targetDate = new Date(date);

        return currentDate > targetDate;
    };

    if (camp === null) {
        return <NotFound404 />;
    }

    const handleProceedToCheckout = () => {
        logEvent({
            category: "Button",
            action: "Click",
            label: `checkout-${campId}`,
        });
        const updatedCampDetails = camp;
        if (isAfter('2025-04-10')) {
            updatedCampDetails.registrationFee = camp.original_fee;
        }
        dispatch(selectCourse(updatedCampDetails));
        navigate('/checkout');
    };

    const handleLearnMore = async () => {
        logEvent({
            category: "Button",
            action: "Click",
            label: `learn-more-${campId}`,
        });

        handleShow();
    };

    return (
        <>
            <div className="relative pt-12 pb-12 bg-black xl:pt-12 sm:pb-12 lg:pb-12 xl:pb-12 2xl:pb-12">
                <div className="absolute inset-0">
                    <img className="object-cover w-full h-full" src="/images/summer-camp.jpeg" alt="" />
                    <div className="absolute inset-0 bg-black opacity-65"></div>
                </div>

                <div className="relative opacity-100 leading-relaxed animate-fadeIn">
                    <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
                        <div className="w-full lg:w-2/3 xl:w-1/2">
                            <p className="mt-6 tracking-tighter text-white">
                                <span className="font-sans font-normal text-4xl sm:text-5xl md:text-6xl lg:text-7xl whitespace-nowrap">
                                    {camp.title}
                                </span>
                                <br />
                                <span className="font-serif italic font-normal text-lg sm:text-xl md:text-2xl">
                                    {camp.subtitle}
                                </span>
                            </p>

                            <p className="mt-4 font-sans text-base font-normal leading-7 text-white text-opacity-70">
                                {camp.duration} coding bootcamp{" "}
                                {camp.start_date && <>| Starts {camp.start_date}</>}
                            </p>

                            <p className="mt-4 font-sans text-sm font-normal text-white">
                                Introduce your child to the exciting world of coding and computer
                                science in just 3 days! Our summer bootcamp helps kids explore C
                                programming, brainstorm projects, and interact with tech mentors.
                            </p>

                            {camp.start_date && (
                                <>
                                    {/* Buttons in original position */}
                                    <div ref={buttonRef} className="col-auto mt-3 space-x-3 sm:space-x-4">
                                        <button
                                            onClick={handleProceedToCheckout}
                                            className="inline-flex items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-transparent border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:text-black sm:text-lg focus:ring-offset-secondary mt-2"
                                        >
                                            Enrol Now
                                        </button>
                                        <button
                                            onClick={handleLearnMore}
                                            className="inline-flex items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-transparent border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:text-black sm:text-lg focus:ring-offset-secondary mt-2"
                                        >
                                            Explore more
                                        </button>
                                        <Link to={`https://wa.me/918886883905?text=${encodeURIComponent(`Hello! I'm interested to enroll in ${camp.title}. Are there any upcoming batches?`)}`} target="_blank" rel="noopener noreferrer"
                                            className="inline-flex items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-transparent border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:text-black sm:text-lg focus:ring-offset-secondary mt-2"
                                        >
                                            Talk to the trainer
                                        </Link>
                                    </div>

                                    {/* Floating buttons */}
                                    {isSticky && (
                                        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-transparent z-50">
                                            <button
                                                onClick={handleProceedToCheckout}
                                                className="inline-flex opacity-80 items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-dark border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-grey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:text-white sm:text-lg focus:ring-offset-secondary"
                                            >
                                                Enrol Now
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-4 md:py-4 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative overflow-hidden z-10">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-col lg:flex-row gap-6 justify-center">
                        <div className="w-full">

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Ready to explore the world of Coding?
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <p>
                                        Join <strong>GoGetterGeeks Summer Bootcamp</strong> and spark your curiosity with an exciting 3-day learning experience.
                                        <br /><br />
                                        Designed for kids in 7th grade and above, this interactive camp introduces coding concepts, computer basics, and C
                                        programming. Through engaging sessions, project brainstorming, and hands-on implementation, kids explore their
                                        potential and gain valuable tech skills. Plus, parents get insights into future career paths for their young tech
                                        enthusiasts.
                                        <br /><br />
                                        All you need is a laptop and a stable internet connection - <strong>register now and dive into the world of innovation!</strong>
                                    </p>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Why are we bringing this course?
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <p>
                                        <strong>Empowering Young Innovators:</strong>
                                        <br />
                                        We aim to introduce children to the fascinating world of computer science, nurturing curiosity and
                                        building a strong foundation in technology.

                                        <br /><br />
                                        <strong>Bridging Learning with Real-World Skills:</strong>
                                        <br />
                                        Our course blends theoretical knowledge with practical applications, encouraging kids to think
                                        creatively, solve problems, and confidently explore future tech careers.
                                        <br /><br />
                                        <strong>Guiding Parents in Career Exploration:</strong>
                                        <br />
                                        We engage parents in the learning journey, helping them discover suitable career paths aligned with
                                        their child’s potential in the tech world.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Why should you choose this course?
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <p>
                                        <strong>1. Develop Problem-Solving & Critical Thinking:</strong>
                                        <br />
                                        Our hands-on activities challenge kids to think logically, solve problems, and develop analytical skills that
                                        extend beyond coding.
                                        <br /><br />

                                        <strong>2. Early exposure to Computer Science & Tech:</strong>
                                        <br />
                                        This course introduces young minds to how computers work, giving them a head start in understanding
                                        technology and its future applications.
                                        <br /><br />

                                        <strong>3. Explore Career possibilities:</strong>
                                        <br />
                                        With brainstorming sessions and parental interaction, kids discover career paths in the tech world,
                                        paving the way for future success. 🚀
                                    </p>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Course Overview
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <ul className="flex flex-col pt-2">
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>Day-1:&nbsp;</strong>
                                                Introduction to Computers, How Computers work, Introduction to Programming Languages, C Programming - Installation & Setup,
                                                Data Types, Condition Control.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>Day-2:&nbsp;</strong>
                                                Loop Controls, Arrays, Functions, Project Brainstorming
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>Day-3:&nbsp;</strong>
                                                Project Implementation, Closing Notes, Parent Interaction
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-6'>
                                    <div className="flex items-center mt-5 space-x-3 sm:space-x-4">
                                        <button
                                            onClick={handleLearnMore}
                                            className="inline-flex items-center justify-center px-3 py-0 font-sans text-base font-semibold transition-all duration-200 bg-primary
                                                border-2 border-primary rounded-full sm:leading-8 text-white hover:bg-primary focus:outline-none focus:ring-2 
                                                focus:ring-offset-2 focus:ring-primary sm:text-lg"
                                        >
                                            Learn more
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        What will your child learn & earn?
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <ul className="flex flex-col pt-2">
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>1.&nbsp;</strong>
                                                Introduction to Computer Science: Understanding how computers work and the basics of technology.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>2.&nbsp;</strong>
                                                Fundamentals of C Programming: Exploring coding logic and basic programming 2. concepts.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>3.&nbsp;</strong>
                                                Hands-On Project Development: Brainstorming, implementing, and showcasing coding projects.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>4.&nbsp;</strong>
                                                Problem-Solving & Critical Thinking: Developing logical reasoning and analytical skills.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>5.&nbsp;</strong>
                                                Exploring Tech Career Paths: Engaging parents to explore future career opportunities in tech.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <p className='mt-3'>
                                    <strong>Bonus: All participants will receive a Certificate of Completion at the end of the camp!</strong>
                                </p>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Meet the Trainer
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="container">
                                    <div className="grid grid-cols-12 md:gap-8">
                                        <div className="col-span-12 lg:col-span-2">
                                            <div className="z-[1] flex relative h-full w-full">
                                                <div>
                                                    <img
                                                        src="/images/teacher/adityasolge-50.jpeg"
                                                        alt="Aditya Solge"
                                                        className="img-fluid"
                                                        width={300}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-9">
                                            <p className="break-words">
                                                Aditya Solge is a seasoned tech freelancer and founder of GoGetterGeeks, a platform dedicated
                                                to helping aspiring coders break into the tech industry. With vast experience as a developer and
                                                manager at Amazon, Aditya brings a unique blend of hands-on coding expertise and industry
                                                insights. His passion for mentoring and guiding students ensures that young minds gain practical
                                                knowledge and confidence to excel in tech careers.
                                            </p>
                                            <p className="break-words mt-4">
                                                Learn from the best and embark on your coding journey!
                                            </p>
                                            <div className="grid grid-cols-12 mt-1 mb-2">
                                                <div className="col-span-12 lg:col-span-3 me-1 mt-1">
                                                    <Link to="https://www.linkedin.com/in/adityasolge/" target="_blank" rel="noopener noreferrer" className="px-6 py-3 bg-[#0077B5] text-white rounded-md hover:bg-[#005582] focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center space-x-2">
                                                        <FaLinkedin size={24} className="text-white mb-1" />
                                                        <span>Linkedin</span>
                                                    </Link>
                                                </div>
                                                <div className="col-span-12 lg:col-span-3 me-1 mt-1">
                                                    <Link to="https://topmate.io/adityasolge/" target="_blank" rel="noopener noreferrer" className="px-6 py-3 bg-[#e44432] text-white rounded-md hover:bg-[#cd3d2d] focus:outline-none focus:ring-2 focus:ring-red-500 flex items-center space-x-2">
                                                        <img src='/images/icons/topmate.svg' width={28} className='bg-white p-1 rounded' alt='topmate' />
                                                        <span>Topmate</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Profile picture */}

                                    </div>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Testimonials from past students
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="container">
                                    <Testimonies includeHeading={false} />
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Enrolment and Pricing
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <ul className="flex flex-col pt-2">
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <div className="inline-flex items-center space-x-1">
                                                    <span>Priced at just</span>
                                                    {isAfter('2025-04-10') ? (
                                                        <h6>{camp.original_fee}/-</h6>
                                                    ) : (
                                                        <h6 className="inline m-0">
                                                            {camp.registrationFee}/- &nbsp;
                                                            <del>{camp.original_fee}</del>
                                                        </h6>
                                                    )}
                                                </div>

                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                Click <button className='text-dark hover:text-primary' onClick={handleProceedToCheckout}>here</button> to confirm your spot today!
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                Early bird discounts available: 10% off for registrations by 10th April 2025
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                We support all major payment options through Razorpay, including UPI, cards, net banking, and wallets.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                Still Confused? Get in touch with us today. &nbsp;
                                                <Link to={`https://wa.me/918886883905?text=${encodeURIComponent(`Hello! I'm interested to enroll in ${camp.title}. Are there any upcoming batches?`)}`} target="_blank" rel="noopener noreferrer"
                                                    className="inline-flex opacity-80 items-center justify-center px-3 py-0 font-sans text-base font-semibold transition-all duration-200 bg-dark border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-grey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:text-white sm:text-lg focus:ring-offset-secondary"
                                                >
                                                    Speak to the trainer directly.
                                                </Link>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        FAQs
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <ul className="flex flex-col pt-2">
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>1.&nbsp;</strong>
                                                Who can enrol in this course?
                                                <br />
                                                Students in 7th grade and above with a laptop/PC and a stable internet connection.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>2.&nbsp;</strong>
                                                What will my child learn?
                                                <br />
                                                Basics of computers, an introduction to C programming, hands-on coding, project brainstorming,
                                                and career exploration.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>3.&nbsp;</strong>
                                                How long is the bootcamp?
                                                <br />
                                                It’s a 3-day interactive course designed to build a strong foundation in coding.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>4.&nbsp;</strong>
                                                Is prior coding knowledge required?
                                                <br />
                                                No, this course is beginner-friendly and perfect for kids curious about technology.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>5.&nbsp;</strong>
                                                How can I register?
                                                <br />
                                                Simply visit our website and click “Enrol Now” to get started!
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>6.&nbsp;</strong>
                                                Will there be parental involvement?
                                                <br />
                                                Yes, we involve parents to explore career paths suited to their child’s interests on Day-3
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>7.&nbsp;</strong>
                                                Is there a certification at the end?
                                                <br />
                                                Yes, participants receive a certificate of completion after the course.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>8.&nbsp;</strong>
                                                What is the course fee?
                                                <br />
                                                Details about pricing and early bird discounts are available on the website.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>9.&nbsp;</strong>
                                                Are there any group discounts?
                                                <br />
                                                Yes, contact us for special group pricing for friends/siblings.
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <span className="flex-1">
                                                <strong style={{ fontSize: "large", fontWeight: "bold" }}>10.&nbsp;</strong>
                                                What support is available during the course?
                                                <br />
                                                Our trainer provides live assistance and interactive Q&A sessions.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                <div className="flex justify-between text-sm">
                                    <h4>
                                        Contact & Support
                                    </h4>
                                </div>
                                <hr className="dark:border-slate-700 my-4" />

                                <div className="rounded-md p-0">
                                    <ul className="flex flex-col pt-2">
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <FaWhatsapp size={20} className="flex-none ml-4 mt-1" />
                                            <span className="flex-1">
                                                <Link to={`https://wa.me/918886883905?text=${encodeURIComponent(`Hello! I'm interested to enroll in ${camp.title}. Are there any upcoming batches?`)}`} target="_blank" rel="noopener noreferrer" className="text-dark hover:text-primary mx-1">
                                                    <strong>+91 88868-83905</strong>
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <IoMdCall size={20} className="flex-none ml-4 mt-1" />
                                            <span className="flex-1">
                                                <Link to="tel:+918886883905" target="_blank" rel="noopener noreferrer" className="text-dark hover:text-primary mx-1">
                                                    <strong>+91 88868-83905</strong>
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="flex items-start pb-2 space-x-2 text-base">
                                            <MdOutlineMarkEmailRead size={20} className="flex-none ml-4 mt-1" />
                                            <span className="flex-1">
                                                <Link to="mailto:gogettergeeks@gmail.com" target="_blank" rel="noopener noreferrer" className="text-dark hover:text-primary mx-1">
                                                    <strong>gogettergeeks@gmail.com</strong>
                                                </Link>
                                                for bulk inquiries (agencies/schools who might want to book)
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Dialog open={showModal} className="relative z-10" transition onClose={handleClose}>
                <div className="fixed inset-0 bg-black bg-opacity-25" />

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
                            <DialogTitle
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Download Your Coding Camp Guide Now!
                            </DialogTitle>
                            {errors.message && (
                                <AutoDismissibleAlert type='danger' message={errors.message} />
                            )}

                            {message && (
                                <AutoDismissibleAlert type='success' message={message} />
                            )}
                            <form onSubmit={handleSubmit}>
                                <p className="mt-2 text-sm text-gray-500">
                                    Enter your email to download Summer Coding Camp Guide.
                                </p>
                                <div className="mb-4">
                                    <input type="text" name="email" value={formData.email} onChange={handleInputChange}
                                        className="w-full p-2 mt-2 border border-gray-300 rounded-lg" placeholder='example@email.com'
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                </div>
                                {loading ? (
                                    <div className="mt-4">
                                        <div className="flex items-center justify-center">
                                            <div className="w-8 h-8 border-4 border-primary border-dashed rounded-full animate-spin"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mt-4">
                                        <button className="bg-primary text-white px-4 py-1 rounded">
                                            Download
                                        </button>

                                        <button onClick={handleClose} className="bg-red-500 text-white px-4 py-1 rounded ms-1">
                                            Close
                                        </button>
                                    </div>
                                )}

                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default BootcampDetails;