import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "./GoogleAnalytics";

function RouteChangeTracker() {
    const location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            logPageView();
        }
    }, [location]);

    return null;
};

export default RouteChangeTracker;
