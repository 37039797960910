import React, { useState, useEffect } from "react";

function AutoDismissibleAlert({
  type = "warning",
  message = "This is an alert!",
  dismissTime = 10000,
}) {
  const [show, setShow] = useState(true);
  const [progress, setProgress] = useState(100);

  const alertStyles = {
    primary: "bg-blue-100 text-blue-700 border border-blue-500",
    secondary: "bg-gray-100 text-gray-700 border border-gray-500",
    success: "bg-green-100 text-green-700 border border-green-500",
    danger: "bg-red-100 text-red-700 border border-red-500",
    warning: "bg-yellow-100 text-yellow-700 border border-yellow-500",
    info: "bg-cyan-100 text-cyan-700 border border-cyan-500",
    light: "bg-gray-50 text-gray-700 border border-gray-300",
    dark: "bg-gray-800 text-white border border-gray-700",
  };

  const progressBarColors = {
    primary: "bg-blue-500",
    secondary: "bg-gray-500",
    success: "bg-green-500",
    danger: "bg-red-500",
    warning: "bg-yellow-500",
    info: "bg-cyan-500",
    light: "bg-gray-300",
    dark: "bg-gray-700",
  };

  const alertClass = alertStyles[type] || alertStyles.primary;
  const progressBarColor = progressBarColors[type] || progressBarColors.primary;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, dismissTime);

    const interval = setInterval(() => {
      setProgress((prev) => Math.max(0, prev - (100 / (dismissTime / 100))));
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [dismissTime]);

  if (!show) return null;

  return (
    <div
      className={`${alertClass} relative p-4 rounded-md shadow-md mb-4`}
      role="alert"
    >
      <div className="flex justify-between items-start">
        <strong>{message}</strong>
        <button
          type="button"
          className="ml-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          aria-label="Close"
          onClick={() => setShow(false)}
        >
          ✕
        </button>
      </div>

      {/* Progress Bar */}
      <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
        <div
          className={`${progressBarColor} h-full transition-all duration-100`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
}

export default AutoDismissibleAlert;
