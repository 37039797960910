import { configureStore } from '@reduxjs/toolkit';
import courseReducer from './feature/courseSlice';
import paymentReducer from './feature/paymentSlice';

// Load persisted state from localStorage
const loadState = () => {
    try {
        const serializedState = localStorage.getItem('reduxState');
        return serializedState ? JSON.parse(serializedState) : undefined;
    } catch (error) {
        console.error('Could not load state:', error);
        return undefined;
    }
};

// Save state to localStorage
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serializedState);
    } catch (error) {
        console.error('Could not save state:', error);
    }
};

const store = configureStore({
    reducer: {
        course: courseReducer,
        payment: paymentReducer,
    },
    preloadedState: loadState(),
});

store.subscribe(() => {
    saveState(store.getState());
});

export default store;