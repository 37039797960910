export const downloadFile = async (presignedUrl, fileName) => {
    try {
        const response = await fetch(presignedUrl);

        if (!response.ok) {
            throw new Error("Failed to download file.");
        }

        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName || "g3-downloaded-file";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};