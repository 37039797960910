import React, { useEffect, useState } from 'react';
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Announcement from "../components/Announcement";
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { seoConfig } from '../config/seoConfig';

function AppLayout() {
    const location = useLocation();

    const [title, setTitle] = useState(seoConfig['/'].title);
    const [description, setDescription] = useState(seoConfig['/'].description);

    useEffect(() => {
        const pathConfig = seoConfig[location.pathname] || seoConfig['/'];
        setTitle(pathConfig.title);
        setDescription(pathConfig.description);
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="og:description" content={description} />
            </Helmet>
            {!location.pathname.includes('/short-courses/') && (
                <Announcement />
            )}
            <Header />
            <section className="content-center sm:px-0">
                <Outlet />
            </section>
            <Footer />
        </>
    );
};

export default AppLayout;
