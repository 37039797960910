import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart, selectCourse } from '../feature/courseSlice';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { getCourseById } from '../content/courses';
import Youtube from '../components/YouTubeEmbed';
import NotFound404 from '../components/404';
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { logEvent } from '../analytics/GoogleAnalytics';

function CourseDetails() {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const course = getCourseById(courseId);

    if (course === null) {
        return <NotFound404 />;
    }

    const handleProceedToCheckout = () => {
        logEvent({
            category: "Button",
            action: "Click",
            label: `checkout-${courseId}`,
        });
        dispatch(selectCourse(course));
        navigate('/checkout');
    };

    const handleAddToCart = () => {
        logEvent({
            category: "Button",
            action: "Click",
            label: `add-to-cart-${courseId}`,
        });
        dispatch(addToCart(course));
    };

    return (
        <>
            <div className="relative pt-12 pb-12 bg-black xl:pt-12 sm:pb-12 lg:pb-12 xl:pb-12 2xl:pb-12">
                <div className="absolute inset-0">
                    <img className="object-cover w-full h-full opacity-75" src="/images/course-hero-bg.jpg" alt="" />
                    <div className="absolute inset-0 bg-black opacity-65"></div>
                </div>

                <div className="relative opacity-100 leading-relaxed animate-fadeIn">
                    <div className="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
                        <div className="w-full lg:w-2/3 xl:w-1/2">
                            <p className="mt-6 tracking-tighter text-white">
                                <span className="font-sans font-normal text-7xl">{course.title}</span><br />
                                <span className="font-serif italic font-normal text-2xl">{course.subTitle}</span>
                            </p>
                            <p className="mt-4 font-sans text-base font-normal leading-7 text-white text-opacity-70">3 Free classes | {course.duration} Course {course.start_date && <>| Starting from {course.start_date}</>}</p>

                            <p className="mt-4 font-sans text-2xl font-normal text-white">
                                Course fee {course.original_fee && (
                                    <span className="line-through text-gray-500 mr-2">{course.original_fee}/-</span>
                                )}{course.fee}/-
                            </p>

                            {course.start_date && course.class_schedule ? (
                                <>
                                    <p className="mt-4 font-sans text-2xl font-normal text-white">
                                        Enroll now for Just {course.registrationFee}/-
                                    </p>

                                    <div className="flex items-center mt-5 space-x-3 sm:space-x-4">
                                        <button
                                            onClick={handleProceedToCheckout}
                                            className="inline-flex items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-transparent
                    border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
                    hover:text-black sm:text-lg focus:ring-offset-secondary"
                                        >
                                            Enroll Now
                                        </button>

                                        <button
                                            onClick={() => handleAddToCart({ course })}
                                            className="inline-flex items-center justify-center px-5 py-2 font-sans text-base font-semibold transition-all duration-200 bg-transparent
                    border-2 rounded-full sm:leading-8 text-white border-primary hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
                    hover:text-black sm:text-lg focus:ring-offset-secondary"
                                        >
                                            <FaShoppingCart className="me-1" />
                                            Add to Cart
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <p className="mt-2 font-sans text-lg font-normal text-white">
                                    Currently, no batches are planned. WhatsApp us at
                                    <Link to={`https://wa.me/918886883905?text=${encodeURIComponent(`I am interested in ${course.title}`)}`} target="_blank" rel="noopener noreferrer" className="text-primary mx-1">
                                        <strong>+91 8886883905</strong>
                                    </Link>
                                    for updates!
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-4 md:py-4 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative overflow-hidden z-10">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-col lg:flex-row gap-6 justify-center">
                        <div className="w-full">
                            {course.description &&
                                Object.keys(course.description).map((key) => (
                                    <div key={key} className="bg-[#f7f7f7] dark:bg-slate-800 rounded-xl p-4 md:p-6 mb-4">
                                        <div className="flex justify-between text-sm">
                                            <h4>
                                                {key
                                                    .replace(/([A-Z])/g, " $1")
                                                    .replace(/^./, (str) => str.toUpperCase())
                                                }
                                            </h4>
                                        </div>
                                        <hr className="dark:border-slate-700 my-4" />

                                        <div className="rounded-md p-0">
                                            <div className="opacity-100 leading-relaxed animate-fadeIn">
                                                {course.description[key].subTitle && (
                                                    <h6 className="mb-2">{course.description[key].subTitle}</h6>
                                                )}

                                                {course.description[key].paragraph && (
                                                    <p>{course.description[key].paragraph}</p>
                                                )}

                                                {course.description[key].bulletList && (
                                                    <ul className="flex flex-col pt-2">
                                                        {course.description[key].bulletList.map((item, index) => (
                                                            <li
                                                                key={index}
                                                                className="flex items-start pb-2 space-x-2 text-base"
                                                            >
                                                                <IoMdCheckboxOutline size={20} className="flex-none ml-4 mt-1" />
                                                                <span className="flex-1">{item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>


                                                )}

                                                {course.description[key].videoId && (
                                                    <div className='mt-2'>
                                                        <Youtube id={course.description[key].videoId} title="Play:Youtube" />
                                                    </div>
                                                )}

                                                {course.description[key].button && (
                                                    <div className='mt-6'>
                                                        {course.start_date && course.class_schedule ? (
                                                            <button
                                                                onClick={handleProceedToCheckout}
                                                                title=""
                                                                className="inline-flex items-center justify-center px-3 py-0 font-sans text-base font-semibold transition-all duration-200 bg-primary
                                                            border-2 border-primary rounded-full sm:leading-8 text-white hover:bg-primary focus:outline-none focus:ring-2 
                                                            focus:ring-offset-2 focus:ring-primary sm:text-lg"
                                                                role="button"
                                                            >
                                                                Enroll Now
                                                            </button>
                                                        ) : (
                                                            <p>
                                                                Currently, no batches are planned. WhatsApp us at
                                                                <Link to={`https://wa.me/918886883905?text=${encodeURIComponent(`Hello! I'm interested to enroll in ${course.title}. Are there any upcoming batches?`)}`} target="_blank" rel="noopener noreferrer" className="text-primary mx-1">
                                                                    <strong>+91 8886883905</strong>
                                                                </Link>
                                                                for updates!
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CourseDetails;