import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function ScrollToElement() {
    const location = useLocation();

    useEffect(() => {
        // Check if there's a hash in the URL
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1)); // remove the '#' character
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return null;
}

export default ScrollToElement;