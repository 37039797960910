import { FaMedium } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

function SocialMediaBanner() {
    return (
        <div className="relative isolate overflow-hidden border rounded-lg py-8 bg-gradient-to-r from-[#fff9e6] via-white to-[#fff9e6]">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                    <div className="max-w-xl lg:max-w-lg">
                        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Join Our Coding Family!</h2>
                        <p className="mt-4 text-lg leading-8">
                            We regularly provide valuable content, offered in both written articles and video formats, completely free for you to learn and benefit from.
                        </p>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2">
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-black p-2 ring-1 ring-white/10">
                                <FaMedium className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <dt className="mt-4 font-semibold ">Tech Blogs</dt>
                            <dd className="mt-2 leading-7">
                                <Link href="https://adityasolge.medium.com/" className="font-medium text-primary underline">Follow</Link> to us on Medium if you prefer written content.
                            </dd>
                        </div>
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-[#FF0000] p-2 ring-1 ring-white/10">
                                <FaYoutube className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <dt className="mt-4 font-semibold">Tech Videos</dt>
                            <dd className="mt-2 leading-7">
                                <Link href="https://www.youtube.com/@gogettergeeks" className="font-medium text-primary underline">Subscribe</Link> to us on YouTube if you prefer video content.
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

        </div>
    )
}

export default SocialMediaBanner;